import { useNavigate } from '@remix-run/react'
import { Button } from './ui/button'

const ErrorMessage = ({ error }: { error: Error }) => {
  console.log(error)
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <div className='text-2xl font-bold mb-6'>
        Oh no! Something went wrong..
      </div>
      <img src='/cat2.jpg' alt='Cat' className='w-40 h-40 mb-6' />
      <div className='text-red-600'>{error.message}.</div>
      <Button onClick={goBack} className='mt-4'>
        Go Back
      </Button>
    </div>
  )
}

export default ErrorMessage
